import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { DefaultLayoutComponent } from './containers';
import { ButtonModule, CardModule, FormModule, GridModule } from '@coreui/angular-pro';
import {LoginRoutingModule} from './views/login/login-routing.module';
// import { Page404Component } from './views/pages/page404/page404.component';
// import { Page500Component } from './views/pages/page500/page500.component';
// import { RegisterComponent } from './views/pages/register/register.component';
import { AuthGuard } from './_helpers';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'cp',
    pathMatch: 'full'
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      {
        path: 'db',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/dashboard/dashboard.module').then((m) => m.DashboardModule)
      },

      {
        path: 'cp',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/consumerprofile/consumerprofile.module').then((m) => m.ConsumerProfileModule)
      },
      {
        path: 'cms',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/celerymemorystructures/celerymemorystructures.module').then((m) => m.CelerymemorystructuresModule)
      },
      {
        path: 'cpi',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/celeryproductideas/celeryproductideas.module').then((m) => m.CeleryProductIdeasModule)
      },{
        path: 'ads',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/advertisement/ads/ads.module').then((m) => m.AdsModule)
      },
      {
        path: 'messaging',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/celeryadmessaging/celeryadmessaging.module').then((m) => m.CeleryadmessagingModule)
      },
      {
        path: 'micro',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/celerymicrooccassions/microoccassions.module').then((m) => m.MicrooccassionsModule)
      },
      {
        path: 'microfestivals',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/celerymicrofestivals/microfestivals.module').then((m) => m.MicrofestivalsModule)
      },
      {
        path: 'bulkstatus',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/bulkstatus/bulkstatus.module').then((m) => m.BulkStatusModule)
      },
      {
        path: 'desire',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/desire/desirescore/desire.module').then((m) => m.DesireModule)
      },
      {
        path: 'desirerewrite',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/desire/desirerewrite/desirerewrite.module').then((m) => m.DesireRewriteModule)
      },
      {
        path: 'neuro',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/neuro/analyze/neuroanalyze.module').then((m) => m.NeuroAnalyzeModule)
      },
      {
        path: 'neuroadrewrite',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/neuro/neuroadrewrite/neuroadrewrite.module').then((m) => m.NeuroAdRewriteModule)
      },
      {
        path: 'frd',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/fragrance/fragrancedesign/fragrancedesign.module').then((m) => m.FragranceDesignModule)
      },
      {
        path: 'blend',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/fragrance/fragrancecomposition/fragrancecomposition.module').then((m) => m.FragranceCompositionModule)
      },
      {
        path: 'frs',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/flavor/flavorstaging/flavorstaging.module').then((m) => m.FlavorStagingModule)
      },
      {
        path: 'flc',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/flavor/flavorcomposition/flavorcomposition.module').then((m) => m.FlavorCompositionModule)
      },
      {
        path: 'musicgen',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/music/musicgen/music.module').then((m) => m.MusicModule)
      },
      {
        path: 'musiccompose',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/music/musiccompose/musiccompose.module').then((m) => m.MusicComposeModule)
      },
      {
        path: 'adrewrite',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/advertisement/adrewrite/adrewrite.module').then((m) => m.AdRewriteModule)
      },
      {
        path: 'celerybranddesign',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/celerybranddesign/celerybranddesign.module').then((m) => m.CeleryBrandDesignModule)
      },
      {
        path: 'brandnames',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/brands/brandnames/brandnames.module').then((m) => m.BrandNamesModule)
      },
      {
        path: 'cbm',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/cbm/cbm.module').then((m) => m.CbmModule)
      },
      {
        path: 'bl',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/baseline/baseline.module').then((m) => m.BaselineModule)
      },
      {
        path: 'pr',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/pricing/priceline.module').then((m) => m.PricelineModule)
      },
      {
        path: 'pro',canActivate: [AuthGuard],
        loadChildren: () =>
          import('./views/promotions/promotions.module').then((m) => m.PromotionsModule)
      },
      {
        path: 'login',
        loadChildren: () =>
          import('./views/login/login.module').then((m) => m.LoginModule)
      },
    ]
  },
  // {
  //   path: '404',
  //   component: Page404Component,
  //   data: {
  //     title: 'Page 404'
  //   }
  // },
  // {
  //   path: '500',
  //   component: Page500Component,
  //   data: {
  //     title: 'Page 500'
  //   }
  // },
  { path: '**', redirectTo: 'cp'}
];

@NgModule({
  imports: [
    CommonModule,
    CardModule,
    GridModule,
    FormModule,
    LoginRoutingModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
      initialNavigation: 'enabledBlocking'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
